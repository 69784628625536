@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-bold-rounded/css/uicons-bold-rounded.css');
@import url('https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css');
@import url('https://cdn-uicons.flaticon.com/uicons-solid-straight/css/uicons-solid-straight.css');

body {
	background: #000;
}

* {
  scrollbar-width: auto;
  scrollbar-color: #000 #ffffff;
  
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 10px;
  border: 3px solid #ffffff;
  
}


.active,
.miactHrHeader {
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  z-index: 2;
}

.miactHrHeader {
  backdrop-filter: blur(0px);
  background-color: rgba(0,0,0,0);
  transition: .6s;
  z-index: 2;
}

.active {
  backdrop-filter: blur(10px);
  background-color: rgba(93, 0, 122, 0.95);
  z-index: 2;
}


.anchor::before {
	content:"";
	display: block;
	height: 117px;
	margin:-117px 0 0;
}


.anchorPoint::before {
	content:"";
	display: block;
	height: 147px;
	margin:-147px 0 0;
}



/*
==============================================
pulse
==============================================
*/

.pulse{
	animation-name: pulse;
	-webkit-animation-name: pulse;	

	animation-duration: 1.5s;	
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 1;		
	}
	50% {
		transform: scale(1);
		opacity: 1;	
	}	
	100% {
		transform: scale(0.9);
		opacity: 1;	
	}			
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.90);
		opacity: 1;		
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;	
	}	
	100% {
		-webkit-transform: scale(0.90);
		opacity: 1;	
	}			
}










.chat-thread {
	scrollbar-width: auto;
	scrollbar-color: #000 #ffffff;
	
	}
	
	/* Chrome, Edge, and Safari */
  .chat-thread::-webkit-scrollbar {
	width: 3px;
	}
	
  .chat-thread::-webkit-scrollbar-track {
	background: transparent;
	}
	
  .chat-thread::-webkit-scrollbar-thumb {
	background-color: #6e7e97;
	border-radius: 10px;
	border: 0px solid #ffffff;
	
	}




	.chat-thread {
	  margin: 24px auto 0 auto;
	  list-style: none;
	  overflow-y: scroll;
	  overflow-x: hidden;
	  padding-right: 10px;
	  left: 0;
	  right: 0;
	  position: relative;
	  display: block;
	}
	
	.chat-thread li {
	  position: relative;
	  clear: both;
	  display: inline-block;
	  padding: 16px 40px 16px 20px;
	  margin: 0 0 20px 0;
	  font: 14px/20px "Outfit", sans-serif;
	  border-radius: 10px;
	  background-color: rgba(25, 147, 147, 0.2);
	}
	
	/* Chat - Avatar */
	.chat-thread li:before {
	  position: absolute;
	  top: 0;
	  width: 50px;
	  height: 50px;
	  border-radius: 50px;
	  content: "";
	}
	
	/* Chat - Speech Bubble Arrow */
	.chat-thread li:after {
	  position: absolute;
	  top: 15px;
	  content: "";
	  width: 0;
	  height: 0;
	  border-top: 15px solid rgba(25, 147, 147, 0.2);
	}
	
	.chat-thread li:nth-child(odd) {
	  animation: show-chat-odd 0.15s 1 ease-in;
	  -moz-animation: show-chat-odd 0.15s 1 ease-in;
	  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
	  float: right;
	  margin-right: 90px;
	  color: #c7d4e8;
	}
	
	.chat-thread li:nth-child(odd):before {
	  right: -80px;
	  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTIgMjEyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzExY2ZlNzt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzYiPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iMTA2IiBjeT0iMTA2IiByPSIxMDYiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im0xNDkuOTEsMTU5LjY3YzAsMi43LTIuMTksNC44OC00Ljg4LDQuODhzLTQuODgtMi4xOC00Ljg4LTQuODhjMC0xMC43Ni04Ljc1LTE5LjUyLTE5LjUyLTE5LjUyaC0yOS4yOGMtMTAuNzYsMC0xOS41Miw4Ljc1LTE5LjUyLDE5LjUyLDAsMi43LTIuMTksNC44OC00Ljg4LDQuODhzLTQuODgtMi4xOC00Ljg4LTQuODhjMC0xNi4xNSwxMy4xMy0yOS4yOCwyOS4yOC0yOS4yOGgyOS4yOGMxNi4xNSwwLDI5LjI4LDEzLjEzLDI5LjI4LDI5LjI4Wm00Ljg4LTc1LjYzdjkuNzZjMCw0LjA0LTMuMjgsNy4zMi03LjMyLDcuMzJoLTIuNDRjMCwxMC43Ni04Ljc1LDE5LjUyLTE5LjUyLDE5LjUyaC0zOS4wM2MtMTAuNzYsMC0xOS41Mi04Ljc1LTE5LjUyLTE5LjUyaC0yLjQ0Yy00LjA0LDAtNy4zMi0zLjI4LTcuMzItNy4zMnYtOS43NmMwLTQuMDQsMy4yOC03LjMyLDcuMzItNy4zMmgyLjQ0YzAtMTAuNzYsOC43NS0xOS41MiwxOS41Mi0xOS41MmgxNC42NHYtNC44OGMwLTIuNywyLjE5LTQuODgsNC44OC00Ljg4czQuODgsMi4xOCw0Ljg4LDQuODh2NC44OGgxNC42NGMxMC43NiwwLDE5LjUyLDguNzUsMTkuNTIsMTkuNTJoMi40NGM0LjA0LDAsNy4zMiwzLjI4LDcuMzIsNy4zMlptLTE5LjUyLTcuMzJjMC01LjM4LTQuMzgtOS43Ni05Ljc2LTkuNzZoLTM5LjAzYy01LjM4LDAtOS43Niw0LjM4LTkuNzYsOS43NnYyNC40YzAsNS4zOCw0LjM4LDkuNzYsOS43Niw5Ljc2aDM5LjAzYzUuMzgsMCw5Ljc2LTQuMzgsOS43Ni05Ljc2di0yNC40Wm0tNDEuNDcsNC44OGMtNC4wNCwwLTcuMzIsMy4yOC03LjMyLDcuMzJzMy4yOCw3LjMyLDcuMzIsNy4zMiw3LjMyLTMuMjgsNy4zMi03LjMyLTMuMjgtNy4zMi03LjMyLTcuMzJabTI0LjQsMGMtNC4wNCwwLTcuMzIsMy4yOC03LjMyLDcuMzJzMy4yOCw3LjMyLDcuMzIsNy4zMiw3LjMyLTMuMjgsNy4zMi03LjMyLTMuMjgtNy4zMi03LjMyLTcuMzJaIi8+PC9nPjwvc3ZnPg==");
	}
	
	.chat-thread li:nth-child(odd):after {
	  border-right: 15px solid transparent;
	  right: -15px;
	}
	
	.chat-thread li:nth-child(even) {
	  animation: show-chat-even 0.15s 1 ease-in;
	  -moz-animation: show-chat-even 0.15s 1 ease-in;
	  -webkit-animation: show-chat-even 0.15s 1 ease-in;
	  float: left;
	  margin-left: 120px;
	  color: #c7d4e8;
	}
	
	.chat-thread li:nth-child(even):before {
	  left: -86px;
	  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTIgMjEyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6I2ZlYjIwMDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzYiPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iMTA2IiBjeT0iMTA2IiByPSIxMDYiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im0xMDYsMTA2YzE2LjA0LDAsMjkuMDUtMTMsMjkuMDUtMjkuMDVzLTEzLTI5LjA1LTI5LjA1LTI5LjA1LTI5LjA1LDEzLTI5LjA1LDI5LjA1Yy4wMiwxNi4wNCwxMy4wMSwyOS4wMywyOS4wNSwyOS4wNVptMC00OC40MWMxMC42OSwwLDE5LjM2LDguNjcsMTkuMzYsMTkuMzZzLTguNjcsMTkuMzYtMTkuMzYsMTkuMzYtMTkuMzYtOC42Ny0xOS4zNi0xOS4zNiw4LjY3LTE5LjM2LDE5LjM2LTE5LjM2WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0ibTEwNiwxMTUuNjhjLTI0LjA1LjAzLTQzLjU0LDE5LjUyLTQzLjU3LDQzLjU3LDAsMi42NywyLjE3LDQuODQsNC44NCw0Ljg0czQuODQtMi4xNyw0Ljg0LTQuODRjMC0xOC43MiwxNS4xNy0zMy44OSwzMy44OS0zMy44OXMzMy44OSwxNS4xNywzMy44OSwzMy44OWMwLDIuNjcsMi4xNyw0Ljg0LDQuODQsNC44NHM0Ljg0LTIuMTcsNC44NC00Ljg0Yy0uMDMtMjQuMDUtMTkuNTItNDMuNTQtNDMuNTctNDMuNTdaIi8+PC9nPjwvc3ZnPg==");
	}
	
	.chat-thread li:nth-child(even):after {
	  border-left: 15px solid transparent;
	  left: -15px;
	}
	
	/*
	.chat-window {
	  display: inline;
	  width: 100%;
	  margin-top: 0px;
	}
	*/
	.chat-window-message {
	  width: 100%;
	  height: 48px;
	  font: 16px "Outfit", sans-serif;
	  background: none;
	  color: #48686e;
	  outline: none;
	}
	
	/* Small screens */
	@media all and (max-width: 767px) {
	  /*
	  .chat-thread {
		width: 90%;
		
	  }
	  */
	/*
	  .chat-window {
		left: 5%;
		width: 90%;
	  }
	*/
	
	}
	/* Medium and large screens */
	@media all and (min-width: 768px) {
	
	  /*
	  .chat-thread {
		width: 50%;
	  }
	
	  .chat-window {
	
		left: 25%;
		width: 50%;
	  }
	  */
	}
	@keyframes show-chat-even {
	  0% {
		margin-left: -480px;
	  }
	  100% {
		margin-left: 0;
	  }
	}
	@-moz-keyframes show-chat-even {
	  0% {
		margin-left: -480px;
	  }
	  100% {
		margin-left: 0;
	  }
	}
	@-webkit-keyframes show-chat-even {
	  0% {
		margin-left: -480px;
	  }
	  100% {
		margin-left: 0;
	  }
	}
	@keyframes show-chat-odd {
	  0% {
		margin-right: -480px;
	  }
	  100% {
		margin-right: 0;
	  }
	}
	@-moz-keyframes show-chat-odd {
	  0% {
		margin-right: -480px;
	  }
	  100% {
		margin-right: 0;
	  }
	}
	@-webkit-keyframes show-chat-odd {
	  0% {
		margin-right: -480px;
	  }
	  100% {
		margin-right: 0;
	  }
	}
	























	/*****************
	// -----------------
	// FORM LOADER PAGE 
	// _________________
	// *****************/


	#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999999999999;
	overflow: hidden;
}
.no-js #loader-wrapper {
	display: none;
}

.processing {
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 29%;
	width: 250px;
	height: 250px;

	z-index: 8888888;
	font-size: 33px;
	background: transparent;
	
	
	border: 3px solid transparent;
}

.processing img {
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
	z-index: 9999999999999999;

}

.loaded .processing {
	opacity: 0;
	transition: all 0.3s ease-out;
}

.letsTxt {
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 40%;
	margin: -180px 0px 0px 0px;
	z-index: 11;
	width: 80%;
	font-weight: 700;
	color: #fff;
	text-align: center;
}

.loaded .letsTxt {
	opacity: 0;
	transition: all 0.3s ease-out;
}

#loader {
	
	
	display: block;
	position: absolute;

	text-align: center;
	width: 250px;
	height: 250px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #11cfe7;
	-webkit-animation: spin 1.7s linear infinite;
	animation: spin 1.7s linear infinite;
	z-index: 11;
	
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #feb200;
	-webkit-animation: spin-reverse .6s linear infinite;
	animation: spin-reverse .6s linear infinite;
}

#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #696eff;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
	0% {
	-webkit-transform: rotate(0deg);
	}
	100% {
	-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	}
	100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
	}
}
@-webkit-keyframes spin-reverse {
	0% {
	-webkit-transform: rotate(0deg);
	}
	100% {
	-webkit-transform: rotate(-360deg);
	}
}
@keyframes spin-reverse {
	0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	}
	100% {
	-webkit-transform: rotate(-360deg);
	transform: rotate(-360deg);
	}
}
#loader-wrapper .loader-section {
	position: fixed;
	top: 0;
	width: 50%;
	height: 100%;
	background-color: #fff;
	z-index: 10;

}

#loader-wrapper .loader-section.section-left {
	left: 0;
	background-repeat: no-repeat;
	background-image: url(../images/left-bg.jpg);
	background-position: 100% 100%;
	background-size: 100%;
	background-color: #121a24;
}

#loader-wrapper .loader-section.section-right {
	right: 0;
	background-repeat: no-repeat;
	background-image: url(../images/right-bg.jpg);
	background-position: 0% 100%;
	background-size: 100%;
	background-color: #121a24;
}

/* Loaded styles */
.loaded #loader-wrapper .loader-section.section-left {
	-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
	transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
	-webkit-transform: translateX(100%);
			transform: translateX(100%);
	transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
	opacity: 0;
	transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
	visibility: hidden;
	-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
	transition: all 0.3s 1s ease-out;
}


@media only screen and (max-width: 599px) {

	.letsTxt {
		display: block;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		top: 40%;
		margin: -160px 0px 0px 0px;
		z-index: 11;
		width: 80%;
		font-weight: 700;
		color: #fff;
		text-align: center;
	}



#loader-wrapper .loader-section.section-left {
		left: 0;
		background-repeat: no-repeat;
		background-image: url(../images/left-bg.jpg);
		background-position: 100% 100%;
		background-size: 440%;
		background-color: #121a24;
	}
	
	#loader-wrapper .loader-section.section-right {
		right: 0;
		background-repeat: no-repeat;
		background-image: url(../images/right-bg.jpg);
		background-position: 0% 100%;
		background-size: 440%;
		background-color: #121a24;
	}


}



@media only screen and (max-width: 740px) and (orientation: landscape) {

	.letsTxt {
		display: block;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		top: 40%;
		margin: -120px 0px 0px 0px;
		z-index: 11;
		width: 80%;
		font-weight: 700;
		color: #fff;
		text-align: center;
	}


}




@media only screen and (min-width: 600px) and (orientation: portrait) {
	.letsTxt {
		display: block;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		top: 40%;
		margin: -200px 0px 0px 0px;
		z-index: 11;
		width: 80%;
		font-weight: 700;
		color: #fff;
		text-align: center;
	}
	#loader-wrapper .loader-section.section-left {
		left: 0;
		background-repeat: no-repeat;
		background-image: url(../images/left-bg.jpg);
		background-position: 100% 100%;
		background-size: 340%;
		background-color: #121a24;
	}
	
	#loader-wrapper .loader-section.section-right {
		right: 0;
		background-repeat: no-repeat;
		background-image: url(../images/right-bg.jpg);
		background-position: 0% 100%;
		background-size: 340%;
		background-color: #121a24;
	}


}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape){

	#loader-wrapper .loader-section.section-left {
		left: 0;
		background-repeat: no-repeat;
		background-image: url(../images/left-bg.jpg);
		background-position: 100% 100%;
		background-size: 140%;
		background-color: #121a24;
	}
	
	#loader-wrapper .loader-section.section-right {
		right: 0;
		background-repeat: no-repeat;
		background-image: url(../images/right-bg.jpg);
		background-position: 0% 100%;
		background-size: 140%;
		background-color: #121a24;
	}


}


@media only screen 
and (min-device-width : 375px) 
and (max-device-width : 812px) 
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
	.letsTxt {
		display: block;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		top: 40%;
		margin: -110px 0px 0px 0px;
		z-index: 11;
		width: 80%;
		font-weight: 700;
		color: #fff;
		text-align: center;
		font-size: 22px !important;
	} 
	
}

				




.uploadArea {
    scrollbar-width: auto;
    scrollbar-color: rgba(255,255,255,0);
    
  }
  
  /* Chrome, Edge, and Safari */
  .uploadArea ::-webkit-scrollbar {
    width: 5px;
  }
  
  .uploadArea ::-webkit-scrollbar-track {
    background: rgba(255,255,255,0);
  }
  
  *.uploadArea ::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,0.5);
    border-radius: 109px;
    border: 2px solid rgba(255,255,255,0);
    
  }





  form :where(i, p){
    color: #6990F2;
  }
  form i{
    font-size: 50px;
  }
  form p{
    margin-top: 15px;
    font-size: 16px;
  }
  
  section .row{
    margin-bottom: 10px;
    background: #E9F0FF;
    list-style: none;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100000;
  }
  section .row i{
    color: #696eff;
    font-size: 30px;
  }
  section .details span{
    font-size: 14px;
  }
  .progress-area .row .content{
    width: 100%;
    margin-left: 18px;
  }
  .progress-area .details{
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    justify-content: space-between;
  }
  .progress-area .content .progress-bar{
    height: 6px;
    width: 100%;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 30px;
  }
  .content .progress-bar .progress{
    height: 100%;
    width: 0%;
    background: #11cfe7;
    border-radius: inherit;
  }

  .uploaded-area{
/*
    max-height: 232px;
    overflow-y: scroll;
*/
  }
  .uploaded-area.onprogress{
    /*
    max-height: 150px;
    */
  }
  .uploaded-area::-webkit-scrollbar{
    width: 0px;
  }
  .uploaded-area .row .content{
    display: flex;
    align-items: center;
  }
  .uploaded-area .row .details{
    display: flex;
    margin-left: 15px;
    flex-direction: column;
  }
  .uploaded-area .row .details .size{
    color: #404040;
    font-size: 11px;
  }
  .uploaded-area i.fa-check{
    font-size: 16px;
  }