
.chat-thread {
  margin: 24px auto 0 auto;
  

  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  left: 0;
  right: 0;

}

.chat-thread li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 16px 40px 16px 20px;
  margin: 0 0 20px 0;
  font: 14px/20px "Outfit", sans-serif;
  border-radius: 10px;
  background-color: rgba(25, 147, 147, 0.2);
}

/* Chat - Avatar */
.chat-thread li:before {
  position: absolute;
  top: 0;
  margin-left: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  content: "";
}

/* Chat - Speech Bubble Arrow */
.chat-thread li:after {
  position: absolute;
  top: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
}

.chat-thread li:nth-child(odd) {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  margin-right: 80px;
  color: #c7d4e8;
}

.chat-thread li:nth-child(odd):before {
  right: -80px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTIgMjEyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzExY2ZlNzt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzYiPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iMTA2IiBjeT0iMTA2IiByPSIxMDYiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im0xNDkuOTEsMTU5LjY3YzAsMi43LTIuMTksNC44OC00Ljg4LDQuODhzLTQuODgtMi4xOC00Ljg4LTQuODhjMC0xMC43Ni04Ljc1LTE5LjUyLTE5LjUyLTE5LjUyaC0yOS4yOGMtMTAuNzYsMC0xOS41Miw4Ljc1LTE5LjUyLDE5LjUyLDAsMi43LTIuMTksNC44OC00Ljg4LDQuODhzLTQuODgtMi4xOC00Ljg4LTQuODhjMC0xNi4xNSwxMy4xMy0yOS4yOCwyOS4yOC0yOS4yOGgyOS4yOGMxNi4xNSwwLDI5LjI4LDEzLjEzLDI5LjI4LDI5LjI4Wm00Ljg4LTc1LjYzdjkuNzZjMCw0LjA0LTMuMjgsNy4zMi03LjMyLDcuMzJoLTIuNDRjMCwxMC43Ni04Ljc1LDE5LjUyLTE5LjUyLDE5LjUyaC0zOS4wM2MtMTAuNzYsMC0xOS41Mi04Ljc1LTE5LjUyLTE5LjUyaC0yLjQ0Yy00LjA0LDAtNy4zMi0zLjI4LTcuMzItNy4zMnYtOS43NmMwLTQuMDQsMy4yOC03LjMyLDcuMzItNy4zMmgyLjQ0YzAtMTAuNzYsOC43NS0xOS41MiwxOS41Mi0xOS41MmgxNC42NHYtNC44OGMwLTIuNywyLjE5LTQuODgsNC44OC00Ljg4czQuODgsMi4xOCw0Ljg4LDQuODh2NC44OGgxNC42NGMxMC43NiwwLDE5LjUyLDguNzUsMTkuNTIsMTkuNTJoMi40NGM0LjA0LDAsNy4zMiwzLjI4LDcuMzIsNy4zMlptLTE5LjUyLTcuMzJjMC01LjM4LTQuMzgtOS43Ni05Ljc2LTkuNzZoLTM5LjAzYy01LjM4LDAtOS43Niw0LjM4LTkuNzYsOS43NnYyNC40YzAsNS4zOCw0LjM4LDkuNzYsOS43Niw5Ljc2aDM5LjAzYzUuMzgsMCw5Ljc2LTQuMzgsOS43Ni05Ljc2di0yNC40Wm0tNDEuNDcsNC44OGMtNC4wNCwwLTcuMzIsMy4yOC03LjMyLDcuMzJzMy4yOCw3LjMyLDcuMzIsNy4zMiw3LjMyLTMuMjgsNy4zMi03LjMyLTMuMjgtNy4zMi03LjMyLTcuMzJabTI0LjQsMGMtNC4wNCwwLTcuMzIsMy4yOC03LjMyLDcuMzJzMy4yOCw3LjMyLDcuMzIsNy4zMiw3LjMyLTMuMjgsNy4zMi03LjMyLTMuMjgtNy4zMi03LjMyLTcuMzJaIi8+PC9nPjwvc3ZnPg==");
}

.chat-thread li:nth-child(odd):after {
  border-right: 15px solid transparent;
  right: -15px;
}

.chat-thread li:nth-child(even) {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  float: left;
  margin-left: 80px;
  color: #fff;
}

.chat-thread li:nth-child(even):before {
  left: -80px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJMYXllcl8yIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMTIgMjEyIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6I2ZlYjIwMDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzYiPjxjaXJjbGUgY2xhc3M9ImNscy0yIiBjeD0iMTA2IiBjeT0iMTA2IiByPSIxMDYiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Im0xMDYsMTA2YzE2LjA0LDAsMjkuMDUtMTMsMjkuMDUtMjkuMDVzLTEzLTI5LjA1LTI5LjA1LTI5LjA1LTI5LjA1LDEzLTI5LjA1LDI5LjA1Yy4wMiwxNi4wNCwxMy4wMSwyOS4wMywyOS4wNSwyOS4wNVptMC00OC40MWMxMC42OSwwLDE5LjM2LDguNjcsMTkuMzYsMTkuMzZzLTguNjcsMTkuMzYtMTkuMzYsMTkuMzYtMTkuMzYtOC42Ny0xOS4zNi0xOS4zNiw4LjY3LTE5LjM2LDE5LjM2LTE5LjM2WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0ibTEwNiwxMTUuNjhjLTI0LjA1LjAzLTQzLjU0LDE5LjUyLTQzLjU3LDQzLjU3LDAsMi42NywyLjE3LDQuODQsNC44NCw0Ljg0czQuODQtMi4xNyw0Ljg0LTQuODRjMC0xOC43MiwxNS4xNy0zMy44OSwzMy44OS0zMy44OXMzMy44OSwxNS4xNywzMy44OSwzMy44OWMwLDIuNjcsMi4xNyw0Ljg0LDQuODQsNC44NHM0Ljg0LTIuMTcsNC44NC00Ljg0Yy0uMDMtMjQuMDUtMTkuNTItNDMuNTQtNDMuNTctNDMuNTdaIi8+PC9nPjwvc3ZnPg==");
}

.chat-thread li:nth-child(even):after {
  border-left: 15px solid transparent;
  left: -15px;
}

/*
.chat-window {
  display: inline;
  width: 100%;
  margin-top: 0px;
}
*/
.chat-window-message {
  width: 100%;
  height: 48px;
  font: 16px "Outfit", sans-serif;
  background: none;
  color: #fff;
  outline: none;
}

/* Small screens */
@media all and (max-width: 767px) {
  
  .chat-thread {
    width: 90%;
    display:block;
  }
  
/*
  .chat-window {
    left: 5%;
    width: 90%;
  }
*/

}
/* Medium and large screens */
@media all and (min-width: 768px) {

  /*
  .chat-thread {
    width: 50%;
  }

  .chat-window {

    left: 25%;
    width: 50%;
  }
  */
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}



.chat-thread {
	scrollbar-width: auto;
	scrollbar-color: #000 #ffffff;
	
  }
  
  /* Chrome, Edge, and Safari */
.chat-thread::-webkit-scrollbar {
	width: 3px;
  }
  
.chat-thread::-webkit-scrollbar-track {
	background: transparent;
  }
  
.chat-thread::-webkit-scrollbar-thumb {
	background-color: #6e7e97;
	border-radius: 10px;
	border: 0px solid #ffffff;
	
  }

