.list-card {
  padding: 14px 18px;
  border-radius: 11px;
  margin-bottom: 20px;
  position: relative;
}

.card-header,
.card-footer {
    position: relative;
    border-color: #eaedf7;
    padding-left: 25px;
    padding-right: 25px;
}

.card-header-tab {
    border-bottom: 0;
    padding: 0;
}

.card-item .card-item-icon {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 20px;
    bottom: 21px;
    top: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-item .card-item-icon.card-icon {
    background: var(--primary01);
}

.card-item .card-item-title {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0;
    color: #383942;
}

.card-item .card-item-title small {
    font-size: 80%;
    font-weight: 300;
    text-transform: capitalize;
    color: #8d95b1;
}

.card-item .card-item-body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.card-item .card-item-body .card-item-stat .card-item-figure {
    display: flex;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.5px;
    color: #383942;
}

.card-item .card-item-body .card-item-stat small {
    font-size: 85%;
    font-weight: 300;
    text-transform: capitalize;
    color: #8d95b1;
}

.card-item .card-item-body .card-item-redirect .btn-card-item-view {
    background: #F4F7FD;
    color: #A5ABBA;
    font-weight: 300;
    letter-spacing: 0.5px;
    border: none;
    transition: all, 0.3s;
}

.demo-avatar-group {
  display: flex;
  align-items: center;
}

.demo-avatar-group .main-img-user,
.demo-avatar-group .main-avatar {
  flex-shrink: 0;
}

.demo-avatar-group .main-img-user+.main-img-user,
.demo-avatar-group .main-img-user+.main-avatar {
  margin-left: 5px;
}

.demo-avatar-group .main-avatar+.main-img-user,
.demo-avatar-group .main-avatar+.main-avatar {
  margin-left: -5px;
}

@media (min-width: 576px) {

  .demo-avatar-group .main-img-user+.main-img-user,
  .demo-avatar-group .main-img-user+.main-avatar {
      margin-left: -9px;
      border: 2px solid #ffffff;
  }

  .demo-avatar-group .main-avatar+.main-img-user,
  .demo-avatar-group .main-avatar+.main-avatar {
      margin-left: -9px;
      border: 2px solid #ffffff;
  }
}

@media (min-width: 576px) {

  #shapes .demo-avatar-group .main-img-user+.main-img-user,
  #shapes .demo-avatar-group .main-img-user+.main-avatar {
      margin-left: 9px;
      border: 0px solid #ffffff;
  }
}

.pricingTable2 {
  text-align: center;
  background: #ffffff;
  border: 1px solid #e0e5f3;
}

.pricingTable2-header {
  padding: 25px 10px;
  background-color: #ffffff;
  border-radius: 6px !important;
}

.pricingTable2 .pricingTable2-header h3 {
  font-size: 21px;
  letter-spacing: 2px;
  color: #000000;
  text-transform: uppercase;
  margin: 0px;
}

.pricingTable2-header span {
  display: none;
  font-size: 12px;
}

.card-pricing.pricing1 .list-unstyled li {
  display: block;
}

.pricing-plans {
  padding: 25px 0;
}

.price-value1 {
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
  display: inline-block;
  position: relative;
}

.price-value1 i {
  position: absolute;
  top: 3px;
  font-size: 22px;
}

.price-value1 span {
  font-size: 40px;
  color: #ffffff;
  margin-left: 17px;
}

.pricing-plans .month {
  color: #ffffff;
  display: inline-block;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 100;
}

.pricingContent2 ul {
  padding: 0 15px;
  margin-bottom: 0;
  list-style: none;
}

.pricingContent2 ul li {
  padding: 25px 0 0 0;
  text-transform: capitalize;
}

.pricingContent2 ul li:last-child {
  padding-bottom: 25px;
}

.price-value-content ul li {
  padding: 8px 0;
  text-transform: capitalize;
}

.pricingTable2 .pricingTable2-sign-up {
  padding: 25px 0;
  border-top: 1px solid #e0e5f3;
}

.pricingTable2 .btn-block {
  width: 50%;
  margin: 0 auto;
  padding: 10px 0;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 50px;
  transition: 0.3s ease;
}

.pricingTable2.green .pricing-plans {
  background-color: #10d403;
}

.pricingTable2.info .pricing-plans {
  background-color: #0ab2e6;
}

@media screen and (max-width: 990px) {
  .pricingTable2 {
      margin-bottom: 30px;
  }
}

.MuiDropzoneSnackbar-successAlert {
  background-color: #552177 !important;
  color:#fff !important;
}

.miact-dropzone {
  border: none !important;
  border-width: 0px !important;
}

#dropanother {
  max-height: 100px !important;
}

.miact-dropanother {
  border: none !important;
  border-width: 0px !important;
}

.proceed-button:hover {
  color:black !important;
}

#navbar-menu {
  background-color: rgb(19, 0, 78);
  z-index:2;
}

@media (min-width: 1280px) {
  #navbar-menu {
    display:none;
  }
  #btn-mi-copilot-close {
    display:none;
  }
  #mi-copilot {
    display:block;
  }
}

@media (max-width: 767px) {
  #mi-copilot {
    display:none;
    min-width: 100%;
  }
  #btn-mi-copilot-close {
    display:block;
  }
}

@media (max-width: 991.98px) {
  .main-content-body-show .main-header-menu-icon {
      display: none;
  }

  .main-content-body-show .main-header-left .main-header-arrow {
      display: block;
  }

  .main-content-body-show .main-content-left-chat {
      display: none;
  }

  .main-content-body-show .main-content-body-chat {
      display: flex;
  }
}

.main-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-nav-line-chat {
  border-bottom: 1px solid #e8e8f7;
  padding: 19px;
  flex-shrink: 0;
  flex-direction: row;
  padding-left: 20px;
}

.main-nav-line-chat .nav-link {
  font-size: 13px;
  font-weight: 700;
  color: #3b4863;
  text-transform: uppercase;
}

.main-nav-line-chat .nav-link:hover,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:active {
  outline: none;
}

.main-nav-line-chat .nav-link+.nav-link {
  margin-top: 0;
  margin-left: 30px;
}

.main-chat-contacts-wrapper {
  padding: 10px 20px;
  flex-shrink: 0;
  border-bottom: 1px solid #e8e8f7;
  overflow: hidden;
}

.main-chat-contacts-wrapper .main-content-label,
.main-chat-contacts-wrapper .card-table-two .card-title {
  margin-bottom: 15px;
  line-height: 1;
}

@media (min-width: 992px) {
  .main-chat-contacts-wrapper {
      padding: 10px 15px 10px 0;
  }
}

.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .main-chat-contacts-wrapper .card-title {
  margin-bottom: 15px;
  line-height: 1;
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
  overflow: visible;
}

.main-chat-contacts {
  padding-left: 20px;
  display: flex;
}

.main-chat-contacts .lslide {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: none;
}

.main-chat-contacts small {
  font-size: 11px;
  width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3px;
  text-align: center;
}

.main-chat-contacts-more {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

@media (min-width: 992px) {
  .main-chat-list {
      height: calc(100% - 134px);
      position: relative;
  }
}

.main-chat-list .media {
  padding: 12px 20px;
  border: 1px solid transparent;
  position: relative;
}

.main-chat-list .media .main-img-user {
  flex-shrink: 0;
  top: 3px;
}

.main-chat-list .media+.media {
  margin-top: -1px;
  border-top: 1px solid #e8e8f7;
}

.main-chat-list .media.new {
  background-color: #ffffff;
}

.main-chat-list .media.new .main-img-user span {
  display: flex;
}

.main-chat-list .media.new .media-contact-name span:first-child {
  font-weight: 500;
  color: #404c6d;
}

.main-chat-list .media.new .media-body p {
  color: #8f9cc0;
}

.main-chat-list .media:hover,
.main-chat-list .media:focus {
  cursor: pointer;
  background-color: #f4f5fb;
  border-top-color: #eaedf7;
  border-bottom-color: #eaedf7;
}

.main-chat-list .media:hover:first-child,
.main-chat-list .media:focus:first-child {
  border-top-color: transparent;
}

.main-chat-list .media:hover:last-child,
.main-chat-list .media:focus:last-child {
  border-radius: 0 0 11px 11px;
}

.main-chat-list .media.selected {
  background-color: #f1f2f9;
  border-top-color: #eaedf7;
  border-bottom-color: #eaedf7;
}

.main-chat-list .media.selected:first-child {
  border-top-color: transparent;
}

.main-chat-list .media.selected::after {
  display: block;
}

.main-chat-list .media.selected .media-contact-name span:first-child {
  color: #404c6d;
}

.main-chat-list .media.selected .media-body p {
  color: #8f9cc0;
}

.main-chat-list .main-img-user span {
  position: absolute;
  top: 0;
  left: -2px;
  width: 14px;
  height: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 9px;
  font-weight: 500;
  color: #ffffff;
  background-color: #f16d75;
  box-shadow: 0 0 0 2px #ffffff;
}

.main-chat-list .media-body {
  margin-left: 15px;
}

.main-chat-list .media-body p {
  font-size: 13px;
  margin-bottom: 0;
  color: #a8afc7;
  line-height: 1.35;
}

.main-chat-list .media-contact-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.main-chat-list .media-contact-name span:first-child {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #404c6d;
}

.main-chat-list .media-contact-name span:last-child {
  display: block;
  font-size: 11px;
  color: #a8afc7;
}

@media (min-width: 992px) {
  .main-chat-list .media {
      padding: 12px 15px;
  }
}

.main-content-body-chat {
  flex-direction: column;
}

@media (min-width: 992px) {
  .main-content-body-chat {
      display: flex;
  }
}

.main-chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 12px;
  border-bottom: 1px solid #e8e8f7;
}

.main-chat-header .nav {
  margin-left: auto;
  align-items: center;
}

.main-chat-header .nav-link {
  padding: 0;
  color: #a8afc7;
  font-size: 16px;
  display: none;
}

.main-chat-header .nav-link:first-child {
  display: block;
}

.main-chat-header .nav-link i {
  line-height: 0;
}

.main-chat-header .nav-link .typcn {
  line-height: 0.9;
}

.main-chat-header .nav-link .typcn::before {
  width: auto;
}

.main-chat-header .nav-link+.nav-link {
  margin-left: 15px;
}

@media (min-width: 576px) {
  .main-chat-header .nav-link {
      display: block;
  }

  .main-chat-header .nav-link:first-child {
      display: none;
  }
}

.main-chat-msg-name {
  margin-left: 15px;
}

.main-chat-msg-name h6 {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 700;
  color: #8f9cc0;
}

.main-chat-msg-name small {
  font-size: 12px;
  color: #a8afc7;
}

.main-chat-body {
  position: relative;
}

.main-chat-body .content-inner {
  padding: 20px;
}

.main-chat-body .media+.media {
  margin-top: 20px;
}

.main-chat-body .media.flex-row-reverse .media-body {
  margin-left: 0;
  margin-right: 20px;
  align-items: flex-end;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
  background-color: rgb(233, 233, 249);
  color: #1e1635;
  position: relative;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper:before,
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: rgb(233, 233, 249);
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
  border-width: 8px;
}

@media (min-width: 992px) {
  .main-chat-body {
      padding-bottom: 0;
      height: calc(100% - 105px);
      overflow: hidden;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media.flex-row-reverse .media-body {
      margin-left: 55px;
  }
}

.main-msg-wrapper {
  padding: 10px 15px;
  background-color: #f4f6ff;
  font-size: 13px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
}

.main-msg-wrapper:before,
.main-msg-wrapper:after {
  border-color: transparent #f4f6ff transparent transparent;
  border-image: none;
  border-style: solid;
  border-width: medium;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 5%;
  width: 0;
}

.main-msg-wrapper:after {
  border-width: 8px;
}

.main-chat-body .media-body {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-chat-body .media-body>div:last-child {
  font-size: 11px;
  color: #a8afc7;
  display: flex;
  align-items: center;
}

@media (min-width: 576px) {
  .main-chat-body .media-body {
      margin-right: 55px;
  }
}

.main-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.main-chat-time:first-of-type {
  margin-top: 0;
}

.main-chat-time span {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #e6f3f9;
  padding: 5px;
  border-radius: 4px;
}

.main-chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-top: 1px solid #e8e8f7;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-chat-footer .nav {
  align-items: center;
}

.main-chat-footer .nav-link {
  padding: 0;
  color: #a8afc7;
  font-size: 16px;
  display: none;
}

.main-chat-footer .nav-link+.nav-link {
  margin-left: 10px;
}

.main-chat-footer .form-control {
  flex: 1;
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
}

.main-chat-footer .form-control:hover,
.main-chat-footer .form-control:focus {
  box-shadow: none;
}

@media (min-width: 992px) {
  .main-chat-footer {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      border-radius: 0px 0px 5px 5px;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .nav-link {
      display: block;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .form-control {
      margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .main-chat-footer .form-control {
      margin: 0 20px;
  }
}

.main-msg-send {
  font-size: 20px;
}

.main-content-title {
  color: #170c6b;
  font-weight: 500;
  font-size: 32px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
  padding-left: 20px;
}

@media (min-width: 992px) {

  .main-content-left-contacts .main-content-breadcrumb,
  .main-content-left-contacts .main-content-title {
      padding-left: 0;
  }

  .main-content-body-contacts {
      display: block;
      overflow-y: auto;
  }
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper2 {
  background-color: #e9e9f9;
  color: #1e1635;
  position: relative;
}

.main-msg-wrapper2 {
  padding: 10px 15px;
  background-color: #f4f6ff;
  font-size: 13px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 4px;
  position: relative;
}

.chat-account .contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin: 4px;
  background-color: var(--primary01);
  color: var(--primary-bg-color);
}

.miBotBtn {
  animation: glow 2s ease-out infinite;
}

.miBotBtn::after {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  border-radius: 50%;
  position: absolute;
}

@keyframes glow {
  0% { box-shadow: 0 0 0 0px rgba(105,110,255,1),0 0 0 0px rgba(105,110,255,0.85);  }
  50% { transform: scale(0.95); }
  100% { box-shadow: 0 0 0 15px rgba(0,210,255,0),0 0 0 30px rgba(0,210,255,0); }
}

.miact-dropzone {
  background-color: transparent !important;
}

.MuiSvgIcon-root {
  display:none !important;
}

.ontopofeverything {
  z-index:999999999999999999999999999999999999999999 !important;
}

.float-container {
  background-color: #fff;
  border: 3px solid #fff;
  padding: 20px;
  height:100%;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
  border: 2px solid #292929;
}

.fullscreen-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: black;
}

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
}
.video-container video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%; 
  min-height: 100%; 

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.background-video-1 {
  width: 100%;
  height: auto;
  float: left;
  top: 0;
  padding: none;
  position: absolute; /* optional depending on what you want to do in your app */
  z-index: -1 !important; 
  filter:grayscale(100%); 
  opacity: 0.6;
  overflow-y: hidden;
}

.background-video-2 {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
  object-fit:contain; 
  z-index: -1 !important; 
  filter:grayscale(100%);
  opacity: 0.3;
}

.background-video-3 {
  z-index: 0 !important; filter:grayscale(100%); opacity: 0.6;
}

.notification .landing-notification-body {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    margin-left: 5%;
    margin-right: 5%;
    background: #ffffff;
    position: relative;
    padding: 14px 10px;
    border-radius: 6px;
}

.notification .landing-modal-notification-body {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    margin-left: 1%;
    margin-right: 1%;
    background: #ffffff;
    position: relative;
    padding: 14px 10px;
    border-radius: 6px;
    min-height: 115px;
}

.landing-notify-time {
    display:inline;
    margin-left: auto; 
    margin-right: 0;
    right:0;
}

.landing-modal-notify-time {
    margin-top: 30px;
    padding-right: 30px;
    position:absolute;
    display:inline;
    margin-left: auto; 
    margin-right: 0;
    right:0;
}

@media (max-width: 767px) {
    .landing-notify-time {
        display:inline;
        margin-left: auto; 
        margin-right: 0;
        right:0;
    }
    .landing-modal-notify-time {
        display:inline;
        margin-left: auto; 
        margin-right: 0;
        right:0;
    }
}

.Modal {
    height: 100%;
    width: 100%;
  
    background-color: #ffffff;
    overflow: auto;
  }
  
  .Overlay {
    position: fixed;
  
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  @media (min-width: 768px) and (max-width: 1279px) {
    .Modal {
      width: 80%;
      height: 80%;
    }
  }
  
  @media (min-width: 1280px) {
    .Modal {
      height: auto;
      width: auto;
  
      max-width: 500px;
      max-height: 80%;
    }
  }